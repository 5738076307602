<template>
  <el-dialog title="查看"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="640px"
    :before-close="handleClose">
    <div class="content">
      <el-form ref="form"
        label-width="120px">
        <el-form-item label="保险方案名称:">
          {{info.planName}}
        </el-form-item>
        <el-form-item label="主险:">
          <span v-for="(item,index) in info.mainArr"
            :key="index"><span style="margin-right:10px">{{item.label}}</span>{{item.quota}}</span>
        </el-form-item>
        <el-form-item label="附加险:">
          <span v-for="(item,index) in info.appendArr"
            :key="index"><span style="margin-right:10px">{{item.label}}</span>{{item.quota}}<br /></span>
        </el-form-item>
        <el-form-item label="保险期限:">
          {{info.deadline}}月
        </el-form-item>
        <el-form-item label="预估保费:">
          {{info.forecastPrice}}元
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button type="primary"
        @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>

export default {
  model: {
    prop: 'showCheckForm',
    event: 'change'
  },
  props: {
    showCheckForm: Boolean,
    info: Object
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showCheckForm: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    },
    info: {
      handler(v) {
        if (v) {
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectArr: [{ name: '主险', remark: '添加一个主险', img: '' }, { name: '附加险', remark: '添加一个方案中的附加险', img: '' }, { name: '特别约定', remark: '添加一个方案中的特别约定', img: '' }, { name: '特别说明', remark: '添加一个方案中的特别说明', img: '' }, { name: '免费扩展条款', remark: '添加一个方案中的免费扩展条款', img: '' }, { name: '每次事故绝对免赔额', remark: '添加一个方案中的每次事故绝对免赔额', img: '' }],
      curSelect: null
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'src/style/Standard.scss';
</style>
