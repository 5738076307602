<template>
  <div>
    <div class="type-select">
      <div class="item"
        :class="{ active: curType === null }"
        @click="handleSelect(null)">
        全部({{categoryCount.allCount || '0'}})
      </div>
      <div class="item"
        :class="{ active: curType === 1 }"
        @click="handleSelect(1)">
        工程机械({{categoryCount.engineerCount || '0'}})
      </div>
      <div class="item"
        :class="{ active: curType === 2 }"
        @click="handleSelect(2)">
        车险({{categoryCount.carInsuranceCount || '0'}})
      </div>
      <div class="item"
        :class="{ active: curType === 3 }"
        @click="handleSelect(3)">
        非设备类财产险({{categoryCount.noDeviceCount || '0'}})
      </div>
    </div>
    <GeneralTable ref="GeneralTable"
      tableName="InsuredSchemeTable"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum">
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList"
          v-loading="statisticsLoading" />
      </template>
      <template slot="calculateType"
        slot-scope="{ item }">
        {{item.calculateType === 'agent' ? '代理人': item.calculateType === 'customer' ? '客户' : item.calculateType === 'staff' ? '员工' :'--'}}
      </template>
      <template slot="proxyIntroduceFlag"
        slot-scope="{ item }">
        <div class="point-main">
          <div :class="['point',item.proxyIntroduceFlag?'':'point-no']" />
          <span :class="[item.proxyIntroduceFlag?'point-text':'point-text-no']">{{item.proxyIntroduceFlag?'是':'否'}}</span>
        </div>
      </template>
      <template slot="convertInquiryCount"
        slot-scope="{ item }">
        <div class="point-main">
          <div :class="['point',item.convertInquiryCount > 0?'':'point-no']" />
          <span :class="[item.convertInquiryCount > 0?'point-text':'point-text-no']">{{item.convertInquiryCount > 0?'是':'否'}}</span>
        </div>
      </template>
      <template slot="convertPolicyCount"
        slot-scope="{ item }">
        <div class="point-main">
          <div :class="['point',item.convertPolicyCount > 0?'':'point-no']" />
          <span :class="[item.convertPolicyCount > 0?'point-text':'point-text-no']">{{item.convertPolicyCount > 0?'是':'否'}}</span>
        </div>
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="navToEnquiry(item)"
          v-if="handleHasPerms(`M30-Record-ToEnquiry`)">转询价单</el-button>
        <el-button type="text"
          @click="checkDetail(item)"
          v-if="handleHasPerms(`M30-Record-Detail`)">查看详情</el-button>
      </template>
    </GeneralTable>
    <check-form v-model="showCheckForm"
      :info="curInfo" />
  </div>

</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { dictionaryBatch, getCalculateRecord, delCalculateRecord, exportCalculateRecord, categoryCalculateRecord, diyTitles } from "@/api/policy";
import checkForm from './components/checkForm.vue';
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: 'InsuredSchemeManage',
  components: { GeneralTable, checkForm, StatisticsBox },
  data() {
    return {
      statisticsLoading: false,
      statisticsList: [{
        name: '测算单总数',
        value: 0
      }, {
        name: '测算总保费',
        value: 0
      }, {
        name: '测算总人数',
        value: 0
      }, {
        name: '转保单总数',
        value: 0
      }, {
        name: '转保单保费',
        value: 0
      }, {
        name: '测算单转化率',
        value: 0,
        unit: '%'
      }, {
        name: '测算保费转化率',
        value: 0,
        unit: '%'
      },],
      dataList: [],
      totalNum: 0,
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "测算单号",
          prop: "calculateNo",
          isShow: true,
        },
        {
          label: "保险方案名称",
          prop: "planName",
          isShow: true,
        },
        {
          label: "设备品牌",
          prop: "brandTypeValue",
          isShow: true,
        },
        {
          label: "设备型号",
          prop: "deviceModel",
          isShow: true,
        },
        {
          label: "新机购置价",
          prop: "newPurchasePrice",
          isShow: true,
        },
        {
          label: "预估保费",
          prop: "forecastPrice",
          isShow: true,
        },
        {
          label: "测算用户名称",
          prop: "calculateName",
          isShow: true,
        },
        {
          label: "测算用户类型",
          prop: "calculateType",
          isShow: true,
          isSlot: true
        },
        {
          label: "测算用户联系方式",
          prop: "calculatePhone",
          isShow: true,
        },
        {
          label: "是否代理人推荐",
          prop: "proxyIntroduceFlag",
          isShow: true,
          isSlot: true
        },
        {
          label: "代理人名称",
          prop: "proxyName",
          isShow: true,
        },
        {
          label: "所属业务员",
          prop: "salesmanName",
          isShow: true,
        },
        {
          label: "业务员所属部门",
          prop: "salesmanDeptName",
          isShow: true,
          isPermissions: dataPermissions('M30-View', 'salesmanDeptName')
        },
        {
          label: "测算地点",
          prop: "calculateAddress",
          isShow: true,
        },
        {
          label: "测算时间",
          prop: "calculateTime",
          isShow: true,
        },
        {
          label: "是否转询价单",
          prop: "convertInquiryCount",
          isShow: true,
          isSlot: true
        },
        {
          label: "转询价单时间",
          prop: "convertInquiryTime",
          isShow: true,
        },
        {
          label: "询价单号",
          prop: "inquiryNo",
          isShow: true,
        },
        {
          label: "是否转保单",
          prop: "convertPolicyCount",
          isShow: true,
          isSlot: true
        },
        {
          label: "转保单时间",
          prop: "convertPolicyTime",
          isShow: true,
        },
        {
          label: "保单号",
          prop: "policyNo",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      option: {
        //   isAdd: true, //添加 事件 @add-change
        isDel: hasPerms('M30-Record-del'), //删除 @del-change
        isExport: true,
        //   isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [
          {
            label: "测算单号",
            prop: "calculateNo",
            formType: "input",
            maxLength: 32,
            labelWidth: '110px',
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "保险方案名称",
            prop: "planName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            labelWidth: '110px',
            placeholder: "请输入",
          },
          {
            label: "设备品牌",
            prop: "brandTypeValue",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "测算时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["startDateTime", "endDateTime"],
          },
          {
            label: "测算人名称",
            prop: "calculateName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            labelWidth: '110px',
            placeholder: "请输入",
          },
          {
            label: "测算人类型",
            prop: "calculateType",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: null, text: "全部" },
              { value: 'customer', text: "客户" },
              { value: 'agent', text: "代理人" },
              { value: 'staff', text: "员工" }
            ],
          },
          {
            label: "测算人联系方式",
            prop: "calculatePhone",
            formType: "input",
            maxLength: 32,
            clearable: true,
            labelWidth: '110px',
            placeholder: "请输入",
          },
          {
            label: "是否代理人推荐",
            prop: "proxyIntroduceFlag",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            labelWidth: '110px',
            selectList: [
              { value: null, text: "全部" },
              { value: true, text: "是" },
              { value: false, text: "否" }
            ],
          },
          {
            label: "所属业务员",
            prop: "salesmanName",
            formType: "input",
            labelWidth: '110px',
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "测算地点",
            prop: "calculateAddress",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          //  {
          //    label: "业务员所属部门",
          //    prop: "bindUserName",
          //    formType: "input",
          //    maxLength: 32,
          //    clearable: true,
          //    labelWidth: '110px',
          //    placeholder: "请输入",
          //  },
        ],
      },
      curType: null,
      categoryCount: {},
      showCheckForm: false,
      curInfo: {},
      agentUserId: '',
      loginIdentity: '',
    };
  },
  activated() {
    if (this.$route.query && this.$route.query.agentUserId) {
      this.agentUserId = this.$route.query.agentUserId;
    } else {
      this.agentUserId = '';
    }
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {
    init(data) {
      diyTitles(data).then(res => {
        this.statisticsList[0].value = res.data.totalCount;
        this.statisticsList[1].value = res.data.totalPrice;
        this.statisticsList[2].value = res.data.totalPersonNumber;
        this.statisticsList[3].value = res.data.convertPolicyCount;
        this.statisticsList[4].value = res.data.convertPolicyPremium;
        this.statisticsList[5].value = Number(res.data.calculateOrderRate);
        this.statisticsList[6].value = res.data.policyOrderRate;
      });
    },
    checkDetail(data) {
      data.mainArr = [];
      data.appendArr = [];
      data.plansList.forEach(el => {
        if (el.insuranceType === 0) {
          data.mainArr.push(el);
        } else if (el.insuranceType === 1) {
          data.appendArr.push(el);
        }
      });
      this.curInfo = data;
      this.showCheckForm = true;
    },
    getTotal(data) {
      categoryCalculateRecord(data).then(res => {
        this.categoryCount = res.data;
      });
    },
    handleSelect(i) {
      this.curType = i;
      this.$refs.GeneralTable.getList();
    },
    handleSearch(data) {
      data.index = data.pageNum;
      data.size = data.pageSize;
      data.likeParams = data.keyword;
      data.category = this.curType;
      Object.keys(data).forEach(key => {
        if (!data[key] && data[key] !== false) data[key] = null;
      });
      this.agentUserId ? data.skipUserId = this.agentUserId : '';
      this.getTotal(data);
      this.init(data);
      this.getList(data);
    },
    getList(data) {
      getCalculateRecord(data).then(res => {
        console.log(res);
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm('确认删除记录?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let temp = {
            ids: []
          };
          data.forEach(element => {
            temp.ids.push(element.calculateId);
          });
          //  temp.ids = temp.ids.join(',');
          delCalculateRecord(temp.ids).then(res => {
            if (res) {
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.changeList();
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的记录！');
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    navToEnquiry(item) {
      if (item.category === 1) {
        this.$router.push({
          path: `/routerPolicyManagement/ConstructionMachinery/enquiryAdd?planId=${ item.planId }&calculateId=${ item.calculateId }`,
        });
      } else if (item.category === 2) {
        this.$router.push({
          path: `/routerPolicyManagement/CarInsurance/enquiryAdd?planId=${ item.planId }&calculateId=${ item.calculateId }`,
        });
      } else if (item.category === 3) {
        this.$router.push({
          path: `/routerPolicyManagement/Insurance/enquiryAdd?planId=${ item.planId }&calculateId=${ item.calculateId }`,
        });
      }
    },
    handleExport(data, obj) {
      let ids = [];
      data.forEach(element => {
        ids.push(element.id);
      });
      obj.ids = ids;
      obj.index = obj.pageNum;
      obj.size = obj.pageSize;
      obj.likeParams = obj.keyword;
      obj.category = this.curType;
      exportCalculateRecord(obj).then(res => {
        if (res) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  }
}
</script>

<style lang="scss" scoped>
.point-main {
  display: flex;
  align-items: center;
  .point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #00bc0d;
  }
  .point-text {
    color: #00bc0d;
  }
  .point-no {
    background-color: #c94242ff;
  }
  .point-text-no {
    color: #c94242ff;
  }
}
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>